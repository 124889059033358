.chartContainer {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: 38px 0 16px 40px;
    // overflow: auto;

    @media (max-height: 860px) {
        overflow-y: scroll;
    }

    @media (min-height: 861px) {
        overflow-y: hidden;
    }

    // .Chart, .controls {
    //     @media (min-width: 897px) {
    //         min-width: 1015px;
    //     }
    // }

    @media (max-width: 896px) {
        margin-left: -16px;
        width: 100vw;
        padding: 0;
    }
}

.mobileMonthYear {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: -36%;
    height: 100px;
}

.modalLogo {
    position: absolute;
    left: 25px;
    top: 25px;

    @media (max-width: 896px) {
        display: none;
    }
}

.sliderLikeLabel {
    font-size: 1.6rem;
    line-height: 30px;
    margin-bottom: 0;
    margin-right: 16px;
    font-weight: 600;

    @media (max-width: 896px) {
        margin-right: 0;
    }
}

.openCapitalWithdrawalButton {
    width: auto;
    // margin-top: 0px;
    // line-height: 40px;

    margin-top: 0px;
    line-height: 28px;

    @media (max-width: 896px) {
        width: 100%;
        text-align: left;
    }
}

.openCapitalWithdrawalButtonChange {
    width: auto;
    display: block;
    margin-left: auto;
    margin-top: 0;
    max-height: 27px;

    @media (max-width: 896px) {
        margin-top: 15px;
    }

    &.invalidPresent {
        overflow: visible;

        &:after {
            content: "";
            display: block;
            width: 6px;
            height: 6px;
            background-color: var(--red);
            position: absolute;
            right: -6px;
            top: 0;
            border-radius: 50%;
        }
    }
}

.capitalPurchaseRow {
    color: var(--grey);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.17px;
    line-height: 16px;
    // width: 100%;
    margin-top: 12px;
    max-height: 16px;

    @media (max-width: 896px) {
        max-height: none;
    }

    svg {
        
        &:hover {
            .strokeToggle {
                // stroke: var(--blue-light);
            }
        }
    }
    
    &.invalid {
        svg {
            position: relative;
            bottom: 4px;
        }

        div:first-child {
            transform-origin: left;
            animation: redScale 0.6s ease-in-out 0.6s;
            animation-fill-mode: forwards;
            font-weight: bold;
            color: var(--red);
            // animation: name duration timing-function delay iteration-count direction fill-mode;
        }

        div:last-child {
            // color: var(--red);
        }

        .strokeToggle {
            stroke: var(--grey);
        }

        .fillToggle {
            fill: var(--grey);
        }
    }


    div:last-child {
        margin-left: auto;
    }

}

@keyframes redScale {
    from {
        transform: scale(1.5);
    }

    to {
        color: var(--red);
        transform: scale(1);
    }
}

.topControls {
    // width: 150px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: absolute;
    left: 0;
    top: -40px;
    z-index: 2;



    @media (max-width: 896px) {
        // padding-right: 40px;
    }

    p {
        font-weight: bold;
        // line-height: 95px;
        display: flex;
        flex: 1 100px;

        @media (max-width: 896px) {
            line-height: 1.2em;
        }
    }

    button {
        display: flex;
    }
}

// .waitingForFocus {
//     // transition: box-shadow ease-in-out 0.5s;
//     animation: shadowAnimationIn 1s infinite;
//     animation-timing-function: ease-in-out;
//     animation-direction: alternate-reverse;

//     &.shadowOut {
//         animation: shadowAnimationOut 1s infinite;
//         animation-timing-function: ease-in-out;
//         animation-direction: alternate-reverse;
//     }
// }

// .focusedBlock {
//     box-shadow: inset 0 0 25px -5px transparent;
// }

@keyframes shadowAnimationOut {
    from {
        box-shadow: 0 0 20px 5px rgba(197, 215, 135, 1);
        // opacity: 0.2;
        // transform: scale(0.9);
    }

    to {
        box-shadow: 0 0 20px 5px rgba(197, 215, 135, 0.5);
        // opacity: 0.6;
        // transform: scale(1);
    }
}

@keyframes shadowAnimationIn {
    from {
        // opacity: 0.2;
        box-shadow: 0 0 10px 5px rgba(197, 215, 135, 0.5);
        transform: scale(1);
    }

    to {
        box-shadow: 0 0 10px 5px rgba(197, 215, 135, .8);
        // opacity: 0.6;
        transform: scale(1.005);
    }
}

.sliderWrapper {
    .track {
        width: calc(100% - 25px) !important;

        &>div {
            margin-top: 44px !important;
        }
    }

    .slider_line_progr {
        width: calc(100% + 20px) !important;
    }

    &.capitalBuyInSlider {
        &.inactive {

            .slider_val_parent:last-child,
            .track,
            .slider_footer {
                opacity: 0.5;
                pointer-events: none;
            }

            .sliderInputLabel {
                visibility: hidden;
            }

        }

        .slider_val_parent:last-child {
            max-width: 140px;
            margin-left: 16px;
        }
    }

    &.capitalWithdrawalSlider {
        @media (min-width: 897px) {
            .slider_val_parent {
                margin-left: 0;
            }
        }

        .slider_header {
            display: flex;
            flex-wrap: nowrap !important;

            p {
                max-width: 100px !important;
            }

            &.with-tooltip {
                p {
                    min-width: 128px !important;
                }
            }


            .slider_proc_parent {
                width: auto;
                margin-left: 0 !important;
                margin-right: 0 !important;

                @media (min-width: 897px) {
                    margin-left: auto !important;
                }

                input {
                    width: 35px;
                }

                div {
                    width: 12px !important;
                }
            }

            .slider_val_parent {
                width: 60%;
                margin-left: 16px !important;

                @media (min-width: 897px) {
                    width: auto;
                }
            }
        }

        &.inactive {

            .slider_val_parent:last-child,
            .track,
            .slider_footer {
                opacity: 0.5;
                pointer-events: none;
            }

            .sliderInputLabel {
                visibility: hidden;
            }

            .slider_proc {
                pointer-events: none;
                opacity: 0.5;
            }

        }
    }
}

.tabContainer {
    width: calc(100% + 32px);
    margin-left: -16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px solid rgba(209, 209, 209, 0.6);
    margin-bottom: 41px;
    display: none;

    @media (max-width: 896px) {
        display: flex;
    }

    .mdc-tab--min-width {
        width: auto;
        padding: 0;
    }

    &.capital-tabs {
        @media (max-width: 896px) {
            justify-content: space-evenly;
            padding: 0 16px;

            .mdc-tab {
                &:last-child {
                    margin-right: 16px;
                }

                &:first-child {
                    margin-left: 16px;
                }
            }
        }

        .mdc-tab {
            margin: 0 28px;
        }
    }

}

.capitalContainer {
    @media (min-width: 897px) {
        height: 500px;
    }
}

.controls {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 18px 30px -30px rgba(38, 50, 56, 0.2);
    padding: 30px 8px 0 8px;
    position: relative;
    margin-right: 40px;
    visibility: hidden;
    z-index: 2;


    @media (min-width: 897px) {
        z-index: 1;

        @media (max-width: 1249px) {
            margin-top: 50px;
        }

        margin-top: 32px;
    }

    @media (max-width: 896px) {
        display: none;

        &.mobileModal {
            display: block;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            background-color: var(--grey-light);
            width: 100%;
            height: 100%;
            flex-direction: column;
            z-index: 2;
            flex-wrap: nowrap;
            // padding-top: 100px;
            padding: 100px 16px 16px;
            overflow: auto;
            height: calc(100vh);

            // min-height: 700px;
            .sliders {
                margin-bottom: 60px;
            }
        }
    }

    .sliders {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (min-width: 897px) {
            padding-bottom: 16px;
        }

        @media (max-width: 896px) {
            justify-content: center;
        }

    }

    .sliderWrapperParent {
        max-height: none;
        flex: 1 1 375px;
        // padding: 0 30px;;
        // max-width: 390px;
        max-height: 185px;
        // margin-bottom: 8px;
        @media (max-width: 896px) {
            // for a case with error
            max-height: 250px;

            .capitalBuyInSlider {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }

        @media (min-width: 1250px) {
            max-width: 33%;
            // padding: 0 15px;
        }

        @media (min-width: 1250px) and (max-width: 1400px) {
            max-width: 37%;
        }


        .sliderWrapper {
            max-width: 100%;

            @media (max-width: 896px) {
                padding: 0 10px 18px;
                flex: initial;
                max-width: calc(100vw - 32px);
                width: 100%;
                margin-bottom: 6px;
            }
        }

        @media (max-width: 896px) {
            padding: 0 10px 18px;
            background-color: white;
            border-radius: 8px;
            max-width: calc(100vw - 32px) !important;

            // margin-top: 8px;
            // padding: 0 0;
            &>div {
                margin-top: 15px;
            }

            .slider_header {
                margin-bottom: 0;
            }
        }
    }

    .sliderWrapper {
        flex: 1 1 375px;
        // padding: 0 30px;;
        padding: 0 15px;
        // max-width: 390px;
        max-height: 140px;
        margin-bottom: 32px;

        @media (min-width: 897px) {
            padding: 0 24px;
            margin-bottom: 16px;
        }

        &.one {
            margin-bottom: 0;
        }

        @media (min-width: 1250px) {
            max-width: calc(33% - 30px);
        }

        &.middle {
            max-height: 180px;
        }

        &.capitalBuyInSlider {
            // padding: 0;
            max-height: 160px !important;

            @media (max-width: 896px) {
                .slider_header {
                    display: flex;
                    flex-wrap: wrap;

                    p {
                        width: 100%;
                        text-align: left;
                    }

                    .slider_val_parent {
                        margin-left: 0;
                        justify-content: space-between;

                        &:last-child {
                            margin-left: auto;
                        }
                    }
                }
            }
        }

        .sliderWrapper {
            margin-bottom: 0;
        }

        @media (max-width: 896px) {
            padding: 0 12px 18px;
            flex: initial !important;
            max-width: calc(100vw - 32px) !important;
            width: 100%;
            margin-bottom: 6px;

            .sliderDateInput {
                margin-right: 3px;
            }


            .slider_proc_parent {
                width: 48px;
                margin-right: 0;
            }

            input {
                &.slider_proc {
                    width: auto;
                    max-width: 80%;
                    margin-left: auto;
                }

            }

            div {
                &.slider_proc {
                    margin-right: 0;
                    margin-left: 0;
                    width: 15px;
                }

            }

            .track {
                // padding: 0 0px 0 10px;
            }

            .slider_footer {
                margin: 20px 0 0 0;
            }
        }

        .slider_header {
            // margin-left: -12px;

            p {
                font-size: 1.6rem;
                line-height: 30px;
                margin-bottom: 0;
                margin-right: 16px;
            }

            @media (max-width: 896px) {
                // padding: 3px 4px;
                margin: 15px 0 28px 0;

                p {
                    font-size: 1.4rem;
                }
            }

        }
    }

    .sliderDateInput {
        display: flex;
        position: relative;

        // width: 33.333%;
        // margin-left: auto;
        // justify-content: space-between;
        // padding: 0 20px;

        @media (max-width: 896px) {
            // margin-left: 0;
        }

        .inputWrapper {
            margin-bottom: 0;
            // margin-top: 2rem;
            // width: 35%;

            input {
                height: 38px;
            }
        }

        p {
            margin-bottom: 0;
            margin-top: 3.4rem;
            margin-left: -14px;

            label {
                font-size: 1.45rem;
                margin-bottom: 0;
            }
        }
    }
}