.ReactModal__Body--open {
    .ReactModalPortal {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 10;
    }
}

.ReactModal__Overlay {
    opacity: 0;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    z-index: 9999;
    // iOS fix for opening animation
    animation: opacityAnime01 0.3s;
    animation-timing-function: ease-in-out;

    @keyframes opacityAnime01 {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    // iOS fix for opening animation
    animation: opacityAnime10 0.3s;
    animation-timing-function: ease-in-out;

    @keyframes opacityAnime10 {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }
}

.large-modal {
    position: absolute;
    border: none;
    background: var(--grey-light);
    right: 0;
    top: 0;
    left: 0;
    z-index: 10;
    height: 100vh;
    height: -webkit-fill-available;
    overflow: auto;
    display: flex;

    .closeIcon {
        cursor: pointer;

        .fillToggle {
            fill: var(--grey);
        }
    }

    .modal {
        padding: 8% 0 58px 0;
        max-width: 980px;
        margin: 0 auto auto auto;

        @media (max-width: 896px) {
            padding: 95px 16px;
            width: 100%;
        }

        &.graph {
            .modal-content {
                max-width: 60vw;
                background-color: transparent;
            }
        }
    }

    .modal-header {
        width: 100%;
        max-width: 690px;
        // height: 22px;
        color: #263238;
        font-size: 22px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 30px;
        text-align: center;
        margin-bottom: 65px;
        margin-bottom: 42px;
        padding: 0 32px;
        margin-left: auto;
        margin-right: auto;

        &.buyin {
            max-width: 476px;
        }

        &.withdrawal {
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
        }

        @media (max-width: 700px) {
            margin-bottom: 30px;
        }
    }

    .modal-content {
        width: 100%;
        max-width: 690px;
        border-radius: 8px;
        background-color: #FFFFFF;
        margin: auto;
        padding: 50px 35px;

        @media (max-width: 896px) {
            padding: 16px;
        }

        &.capitalsOverview {
            padding: 0 35px 50px;
            padding-left: 0;
            padding-right: 0;

            .row {
                margin-left: 0;
                margin-right: 0;
            }

            #barSparguthaben {
                position: relative;
            }

            .blockCapital {
                width: 100% !important;
                height: 450px;
                display: flex;
                flex-direction: column;
                background-color: white;
                border-radius: 5px;

                #barCapitalNote {
                    bottom: 0;
                }

                @media (max-width: 896px) {
                    justify-content: center;
                    background-color: transparent;
                }


                // max-height: 360px;
                @media (orientation: portrait) {
                    // max-height: calc(100vh - 242px);
                }

                .inTooltip {

                    .blockTitle,
                    .blockValue {
                        visibility: hidden !important;
                    }
                }

                .blockTitle,
                .blockValue {
                    padding-left: 16px;
                    text-align: left;
                }

                .blockTitle {
                    padding-top: 8px;
                }
            }

            .sliderLikeLabel {
                padding: 0 50px 20px;
                margin-top: 50px;
                border-bottom: 1px solid var(--grey03);
                font-weight: bold;

                @media (max-width: 896px) {
                    padding: 0 16px;

                }
            }
        }

        .slider_header {
            flex-direction: row;
            flex-wrap: wrap;

            p {
                font-size: 16px;
                margin-right: 16px;
                color: var(--grey);
                text-align: left;

                @media (max-width: 896px) {
                    font-size: 14px;
                    width: 100%;
                    text-align: left;
                }
            }
        }

        .sliderDateInput {
            position: relative;


        }

        &.withdrawal {
            max-width: 520px;

            .sliderWrapper {
                margin-top: 32px;
                margin-bottom: 8px;

                @media (max-width: 896px) {
                    // margin-top: -16px;
                    margin-bottom: 16px;

                    &:last-child {
                        // margin-top: -16px;
                        // margin-bottom: 0;
                    }

                    .slider_proc_parent {
                        // max-width: 25%;
                        margin-left: 0;

                        .slider_proc:last-child {
                            width: 25px;
                        }
                    }


                }

            }
        }

        .sliderLikeLabel {
            margin-right: 0;

            @media (max-width: 896px) {
                font-size: 14px;
            }
        }

        .sliderWrapper {
            margin-top: 46px;

            .slider_val_parent {
                &:last-child {
                    max-width: 143px;
                    // margin-left: auto;
                    margin-right: 0;

                    .slider_val {
                        max-width: none;
                    }
                }

                div.slider_val {
                    display: none;
                }
            }
        }


    }
}

.modal-buttons {
    display: flex;
    margin-top: 60px;
    flex-direction: column;
    margin: auto;
    // margin-top: 35px;

    @media (max-width: 700px) {
        flex-wrap: wrap;
    }

    .basicLink {
        margin-top: 16px;
        margin-bottom: 16px;
        font-size: 14px;
    }

    .modal-sub-button {
        width: auto !important;
        height: 56px;
        color: var(--grey);
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 56px;
        text-transform: uppercase;
        margin: 0 32px 0 auto !important;

        @media (max-width: 700px) {
            width: 100% !important;
            margin: 10px 0 0 0 !important;
        }

        &:hover {
            color: #263238;
        }
    }
}