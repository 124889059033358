.blur>*:not(#logo2) {
    filter: blur(3px);
}

.stagger {
    &.in {
        opacity: 0;

    }
}

body.detaillierung {
    .sidebar {
        // z-index: 2;
        background-color: var(--grey-light);

    }
}

.modal .sidebar {
    @media (max-width: 896px) {
        display: none;
    }
}

.sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 320px;
    z-index: 2;
    background-color: var(--grey-light);
    flex: 0 1 120px;

    @media (max-width: 896px) {
        width: 100%;
        flex: 0 0 70px;
        // height: 70px;
        z-index: 10;
    }
}

.main {
    flex: 1;
}

.select-list {
    .neustartLabel {
        cursor: pointer;
        border-radius: 8px;
        // padding-left: 12px;
        // display: inline-block;
        margin-bottom: 16px;

        @media (max-width: 896px) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            position: relative;

            &:hover {
                .fillToggle {
                    fill: var(--main);
                }

                // .strokeToggle {
                //     stroke: var(--main)
                // }
                .backButton {
                    // background-color: transparent;
                    opacity: 1;
                    visibility: visible;
                }
            }

            .backButton {
                width: 100px !important;
                position: absolute;
                bottom: -60px;
                left: 0;
                visibility: hidden;
                text-align: center;
                opacity: 0;
                line-height: 32px;

            }

        }

    }

    @media (max-width: 896px) {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 16px;

        @media (max-height: 340px) {
            margin-top: 0px;
        }


    }
}

.dropdown_content {
    display: none;
    z-index: 5;
    filter: none;

    @media (max-width: 896px) {
        position: absolute;
        transition: all linear 0.3s;
        padding: 20px 16px 0 16px;
        margin-left: 4px;
        margin-top: 50px;
        opacity: 0.98;
        width: 200px;
        border-radius: 6px;
        background-color: #FFFFFF;
        box-shadow: 0 27px 100px -20px rgba(38, 50, 56, 0.3), 0 4px 15px -10px rgba(38, 50, 56, 0.3), 0 9px 30px -15px rgba(38, 50, 56, 0.3), 0 18px 60px -30px rgba(38, 50, 56, 0.3);

        &.opened {
            display: block;
        }

        .point {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: -0.2px;
            line-height: 16px;
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: -7px;
            left: calc(50% - 7px);
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid white;
        }

        &#logo2 {
            &::before {
                left: 7%;
            }
        }

        &#logo4 {
            &::before {
                left: 86%;
            }
        }

    }
}

#logo3 {
    @media (max-width: 896px) {
        margin-left: -75px;
    }
}

// #logo3 {
//     @media (max-width: 896px) {
//         margin-left: -6.3em;
//     }
// }

#logo4 {
    @media (max-width: 896px) {
        margin-left: -153px;
    }
}

.dropdown_back {
    display: none;
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: rgba(162, 165, 166, 0.2);
}

.dropdown_elem {
    margin-bottom: 22px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.17px;
    line-height: 16px;
    transition: all ease-in-out 0.3s;
    color: var(--grey);

    &.current {
        color: var(--blue-light) !important;
    }
}

// remove background for firefox
label.select {

    &:hover,
    &:active,
    &:focus {
        background-color: transparent;
    }
}

.select {
    display: flex;
    align-items: flex-start;
    // margin-bottom: 14px;
    padding-top: 4px;


    & svg {
        width: 19px;
        height: 19px;
    }

    @media (max-width: 896px) {
        margin-bottom: 0px;

        // line-height: 4px;
        &:first-child {
            .circle {
                margin-left: 0;

            }
        }

        &:last-child {
            .circle {
                margin-right: 0;
            }
        }
    }

    &.activated {
        .dropdown button div {
            color: var(--main) !important;
            font-weight: 700;
        }

        .circle {
            background-color: var(--blue-light);
            color: #FFF;
            border-color: var(--blue-light);
        }
    }

    // @media (min-width: 2560px) {
    //   margin-bottom: 0.9722vw;
    // }
}

.main {
    margin-left: 57px;
    margin-top: 69px;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 896px) {
        margin: 0;
        padding-top: 30px;
        // padding-top: 40px;
        position: relative;
        min-height: 465px;

        &:before {
            z-index: 9;
            content: "";
            display: block;
            // position: relative;
            // this causes overflow on old iphones
            position: absolute;
            top: 0;
            height: 40px;
            width: 100%;
            left: 0;
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f3f3f3+0,ffffff+100&1+0,0+100 */
            background: -moz-linear-gradient(top, rgba(243, 243, 243, 1) 0%, rgba(255, 255, 255, 0) 100%);
            /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(243, 243, 243, 1) 0%, rgba(255, 255, 255, 0) 100%);
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(243, 243, 243, 1) 0%, rgba(255, 255, 255, 0) 100%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3f3f3', endColorstr='#00ffffff', GradientType=0);
            /* IE6-9 */

        }
    }
}

.detaill-main {
    margin-left: 60px;
    margin-top: -48px;

    @media (max-width: 896px) {
        margin: 0;
    }
}

.datenq-main {
    margin-left: -28px;
    margin-top: -37px;

    @media (max-width: 1200px) {
        margin: 0;
    }

    &.ich-mochte {
        margin-bottom: 19px;
    }
}

.calend-main {
    margin-left: 58px;
    margin-top: -62px;

    @media (min-height: 660px) {
        min-height: 532px;
    }

    @media (max-width: 896px) {
        margin: 0;
    }
}

.geschl-main {
    margin-left: 54px;
    margin-top: -50px;

    @media (max-width: 896px) {
        margin: 0;
    }
}

.wohn-main {
    margin-left: 50px;
    margin-top: -57px;

    @media (max-width: 896px) {
        margin: 0;
    }
}

.ausg-main {
    margin-left: 57px;
    margin-top: -82px;

    @media (min-height: 660px) {
        min-height: 532px;
    }

    @media (max-width: 896px) {
        margin: 0;
        overflow: auto;
    }
}

.lohn-main {
    margin-left: 57px;
    margin-top: -60px;

    @media (max-width: 896px) {
        margin: 0;
    }
}

.sparg-main {
    margin-left: 55px;
    margin-top: -89px;

    @media (max-width: 896px) {
        margin: 0;
    }
}

.vp-main {
    margin-left: 64px;
    margin-top: -57px;

    @media (max-width: 896px) {
        margin: 0;
    }

    &.ich-mochte {
        margin-bottom: 24px;
    }
}

.ahv-main {
    margin-left: 55px;
    margin-top: -63px;

    @media (max-width: 896px) {
        margin: 0;
    }
}

.fragen-main,
.frag-beant-main {
    // margin-left: auto;
    // margin-right: auto;
    margin-left: 55px;
    margin-top: 55px;
    // margin-top: 118px;

    @media (max-width: 896px) {
        // margin: 0;
        margin-top: 25px;
        margin-left: 0;
    }

    // &~.optional {
    //     display: none;
    // }
}

.frag-beant-main {
    // margin-left: 55px;
    // margin-top: 139px;

    // @media (max-width: 896px) {
    //     margin-left: 0;
    // }
}

.rentenant-main {
    margin-left: 58px;
    margin-top: -28px;

    @media (max-width: 896px) {
        margin: 0;
    }
}

.mainBlock {
    text-align: -webkit-center;
}

.ahv {
    margin-top: 129px;
    margin-bottom: auto;
}

.rente {
    max-width: 476px;
    min-width: 476px;
    padding: 32px 48px;
    height: 266px;
    background-color: white;
    position: relative;
    border-radius: 8px;

    // @media (min-width: 2560px) {
    //   max-width: 33.0556vw;
    //   width: 33.0556vw;
    //   height: 20.5556vw;
    //   border-radius: 0.5556vw;
    //   padding: 2.2222vw 3.3333vw;
    // }
    @media (max-width: 896px) {
        max-width: 90vw;
        min-width: 90vw;
        padding: 0 15px;
        height: 200px;
        margin-top: 12px;

        .number_input {
            height: 46px;

        }
    }

    & p {
        font-size: 16px;
        text-align: left;
        // @media (min-width: 2560px) {
        //   font-size: 1.1111vw;
        // }
    }
}

.fragen {
    width: 482px;
    text-align: -webkit-center;

    // @media (min-width: 2560px) {
    //   width: 27.7778vw;
    // }
    @media (max-width: 896px) {
        width: auto;
        margin-top: 0;
        padding: 0 1rem;

        .ich-mochte {
            margin-top: 0px;
        }
    }

    .ich-mochte {
        margin-top: 38px;
    }

    img {
        // opacity: 0.3;
        animation: opacityScaleAnime 1s infinite;
        animation-timing-function: ease-in-out;
        animation-direction: alternate-reverse;
    }

    @keyframes opacityScaleAnime {
        from {
            opacity: 0.2;
            // transform: scale(0.9);
        }

        to {
            opacity: 0.6;
            // transform: scale(1);
        }
    }

}

.rente__main {
    display: flex;
    // border-bottom: 2px solid var(--grey);
    align-items: baseline;
    // margin-bottom: 16px;
    // @media (min-width: 2560px) {
    //   border-bottom: 0.1389vw solid var(--grey);
    //   margin-bottom: 1.1111vw;
    // }

}

.rente-sub {
    color: var(--grey);
    text-align: left;
    font-size: 14px;
    margin-top: -3px;
}

.preventStaggerOut {
    // transform: none !important;
}

.PKAR {
    .rentenant-main {

        .ich-mochte {
            display: none;
        }

        .rentenanteil {
            height: 250px;

            .slider_header p:not(.infoTextContent) {
                display: none;
            }
        }
    }
}

.rentenanteil {
    border-radius: 8px;
    max-width: 476px;
    min-width: 476px;
    padding: 46px;
    height: 300px;
    background-color: white;
    position: relative;


    // @media (min-width: 2560px) {
    //   max-width: 33.0556vw;
    //   width: 33.0556vw;
    //   height: 20.8333vw;
    //   border-radius: 0.5556vw;
    //   padding: 2.2222vw 3.3333vw;
    // }
    @media (max-width: 896px) {
        max-width: 90vw;
        min-width: 90vw;
        padding: 10px;
        height: 240px;
        margin-top: 12px;
    }

    & p {
        font-size: 16px;
        // @media (min-width: 2560px) {
        //   font-size: 1.1111vw;
        // }
    }
}

.lohnsumme {
    border-radius: 8px;
    max-width: 476px;
    min-width: 476px;
    padding: 32px 48px;
    height: 232px;
    background-color: white;
    position: relative;

    // @media (min-width: 2560px) {
    //   max-width: 33.0556vw;
    //   width: 33.0556vw;
    //   height: 20.5556vw;
    //   border-radius: 0.5556vw;
    //   padding: 2.2222vw 3.3333vw;
    // }
    @media (max-width: 896px) {
        max-width: 90vw;
        min-width: 90vw;
        padding: 8px 16px;
        height: 200px;
        margin-top: 10px;
    }

    & p {
        font-size: 16px;
        // @media (min-width: 2560px) {
        //   font-size: 1.1111vw;
        // }
    }
}

.lohnsumme__main {
    display: flex;
    align-items: baseline;
    // @media (min-width: 2560px) {
    //   border-bottom: 0.1389vw solid var(--grey);
    // }
}

.lohnsumme__button {
    position: absolute;
    bottom: 52px;
    left: 159px;

    // @media (min-width: 2560px) {
    //   bottom: 3.3333vw;
    //   left: 11.3194vw;
    // }
    @media (max-width: 896px) {
        left: 0;
        bottom: 32px;
        width: 100%;
        text-align: center;
    }

    & .button {
        width: 151px;
        padding: 19px 48px;
        margin: 0;
        // @media (min-width: 2560px) {
        //   width: 10.4861vw;
        //   padding: 1.3194vw 3.3333vw;
        // }
    }
}

.number_input {
    // margin-left: 16px;
    width: -webkit-fill-available;
    height: 60px;
    font-weight: bolder;
    font-size: 16px;
    color: var(--grey);
    border: none;

    &.wide input {
        padding-left: 100px;
    }

    input {
        padding-left: 50px;

    }

    label {
        color: var(--main);
    }

    // @media (min-width: 2560px) {
    //   margin-left: 1.1111vw;
    //   width: 26.3889vw;
    //   height: 3.3333vw;
    //   font-size: 1.1111vw;
    // }
    @media (max-width: 400px) {
        //max-width: 143px;
    }

    &:focus {
        outline-offset: 0;
        outline: 0;
    }

    &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: var(--grey);
        font-weight: 500;

    }

    &::-moz-placeholder {
        /* Firefox 19+ */
        color: var(--grey);
        font-weight: 500;
    }

    &:-ms-input-placeholder {
        /* IE 10+ */
        color: var(--grey);
        font-weight: 500;
    }

    &:-moz-placeholder {
        /* Firefox 18- */
        color: var(--grey);
        font-weight: 500;
    }

    &::placeholder {
        color: var(--grey);
        font-weight: 500;

    }
}

.inform {
    display: none;
    text-transform: uppercase !important;

    @media (max-width: 896px) {
        display: block;
        margin-top: 23px;
        margin-right: auto;
        font-size: 12px;
        font-weight: bold;
        line-height: 15px;
        color: var(--grey);
    }
}

.beispiel {
    display: block;
    height: 100%;
}

.modal-document-lohsumme {

    max-width: 304px;

    img {
        position: relative;
        left: -131px;
    }
}

.mobile-modal {
    position: fixed;
    width: 100%;
    width: -webkit-fill-available;
    top: 0;
    bottom: 0;
    height: 100%;
    height: -webkit-fill-available;
    z-index: 10;
    transition: opacity ease-in-out 0.3s;
    background-color: var(--main);
}

.color {
    // background-color: var(--main);
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    height: 100%;

    div {
        font-size: 14px;
        letter-spacing: -0.2px;
        line-height: 20px;
        color: #FFFFFF;

        &:not(.ahv) {
            margin-top: 8px;
        }

        p {
            font-weight: 500;
        }
    }
}

.document {
    height: 430px;
}

.close {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 30px;
    right: 30px;

    @media (max-width: 896px) {
        right: 16px;
    }
}


.optional-text {
    p a {
        color: var(--blue);
        cursor: pointer;
        font-weight: 600;
    }
}

.optional {
    text-align: -webkit-left;
    align-self: center;
    max-width: 375px;
    width: 100%;
    margin-top: 26px;
    margin-left: 32px;
    font-weight: 300;

    // margin-right: 32px;
    @media (max-width: 896px) {
        margin-left: 0;

        &.crossFade {
            display: none;
        }
    }

    // @media (min-width: 2560px) {
    //   width: 26.0417vw;
    //   margin-top: 5.2083vw;
    // }

    &.optional-text {
        @media (max-width: 896px) {
            display: none;
        }

        p {
            color: var(--main);
            width: 310px;
            font-size: 14px;
            letter-spacing: -0.22px;
            margin-bottom: 20px;
            line-height: 20px;
            text-align: left;

            // @media (min-width: 2560px) {
            //   width: 21.5278vw;
            //   font-size: 0.9722vw;
            //   letter-spacing: -0.0139vw;
            //   margin-bottom: 1.6667vw;
            //   line-height: 1.3889vw;
            // }
        }
    }

    &.optional-lohnsumme {
        //max-width: 400px;
        margin-left: 0;
        //position: relative;
        //left: -100px;
        //max-width: 30;

        .doc {
            img {
                position: relative;
                left: -100px;
            }

            p {
                margin-left: 30px;
            }
        }
    }
}

.doc {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    max-width: fit-content;

    @media (max-width: 896px) {
        display: none;
    }

    img {
        // width: 304px;
        height: 430px;
        // @media (min-width: 2560px) {
        //   width: 21.1111vw;
        //   height: 29.8611vw;
        // }
    }

    p {
        margin-top: 9px;
        // @media (min-width: 2560px) {
        //   margin-top: 0.5556vw;
        // }
    }
}

.m-circle {
    width: 48px;
    height: 48px;
    // @media (min-width: 2560px) {
    //   width: 3.3333vw;
    //   height: 3.3333vw;
    // }
}

.elem {
    background: white;
    max-width: 560px;
    border-radius: 8px;
    box-shadow: 0 4px 15px -10px rgba(38, 50, 56, 0.2);
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 19px;
    padding: 22px;
    margin: 20px;
    cursor: pointer;
    transition: box-shadow ease-in-out 0.2s,
        color ease-in-out 0.2s,
        fill ease-in-out 0.2s,
        background-color ease-in-out 0.2s;

    @media (max-width: 896px) {
        padding: 1.7rem 1.7rem 1.8rem 1.7rem;
        margin: 13px 0;
    }

    &:not(:hover) {
        fill: var(--main);
    }

    &:hover {
        fill: var(--green-hover);
        box-shadow: 0 27px 100px -20px rgba(38, 50, 56, .2),
            0 4px 15px -10px rgba(38, 50, 56, .2),
            0 9px 30px -15px rgba(38, 50, 56, .2),
            0 18px 60px -30px rgba(38, 50, 56, .2);
        color: var(--green-dark2);
        ;

        p {
            //transition: color ease-in-out 0.1s;
            color: var(--main);
        }

        h4 {
            transition: color ease-in-out 0.2s;
            color: var(--green-dark);

        }
    }

    &.box {
        // &:active {
        //     background-color: var(--green-hover);
        //     color: white;
        // }

        &.activeChoice {
            img {
                background-color: var(--green-hover);
            }


        }
    }
}

.activeChoice {
    img {
        background-color: var(--green-hover);
    }

    h4 {
        color: var(--green-dark);
    }
}

.box {
    width: 304px;
    height: 241px;
    text-align: center;
    color: var(--main);
    // @media (min-width: 2560px) {
    //   width: 21.1111vw;
    //   height: 16.7361vw;
    // }

    @media (max-width: 896px) {
        display: flex;
        min-width: 91.2vw;
        align-items: center;
        height: auto;
        flex-basis: 0;

        &.elem {
            max-height: none;
            padding: 0.35rem 1.2rem;
            margin: 6px 0;

            p {
                margin-bottom: 1.2rem;
            }
        }
    }

    & img {
        width: 96px;
        height: 96px;
        background-color: var(--main);
        border-radius: 50px;
        margin-bottom: 24px;
        transition: background-color ease-in-out 0.2s;

        // @media (min-width: 2560px) {
        //   height: 6.6667vw;
        //   width: 6.6667vw;
        //   margin-bottom: 1.6667vw;
        //   border-radius: 50%;
        // }
        @media (max-width: 896px) {
            margin-bottom: 0;
            margin-right: 37px;
        }
    }

    &:hover {
        & img {
            background-color: var(--green-hover);
        }
    }
}



.box-text {
    margin-top: 2px;

    @media (max-width: 896px) {
        width: 468px;
        text-align-last: start;
        padding-top: 3px;
    }
}

.blocks {
    // width: 803px;
    width: calc(100vw - 340px);
    max-width: 803px;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;

    // @media (min-width: 2560px) {
    //   width: 45.7639vw;
    //   height: 36.8056vw;
    // }
    @media (max-width: 896px) {
        overflow: auto;
        width: auto;
        margin-top: 10px;
    }
}

.logo {
    visibility: hidden;
    // height: 50px;
    // width: 111px;

    // @media (min-width: 2560px) {
    //   height: 3.4722vw;
    //   width: 7.7083vw;
    // }
    @media (max-width: 896px) {
        display: none;
    }
}

.selects {
    // flex-grow: 1;
    flex-grow: 0.92;

    @media (min-width: 897px) {
        // min-height: 40vh;
    }

    @media (max-width: 896px) {
        display: flex;
        justify-content: center;
    }
}

// .selects {
//     flex-grow: 1.2;

//     @media (max-width: 896px) {
//         display: flex;
//         justify-content: center;
//     }
// }

.neustart {
    flex-direction: column;
    display: flex;
    align-items: flex-start;

    @media (max-width: 896px) {
        margin-bottom: 0;
        // for firefox
        width: 100%;
        // for ios
        width: -webkit-fill-available;
    }
}

.list {
    height: 60vh;
    overflow: inherit;

    @media (max-width: 896px) {
        height: auto;
        overflow: auto;
    }
}

.mobile_back {
    display: none;
    z-index: 1;
    background-color: var(--blue);
    position: absolute;
    width: 100%;
    height: 100%;
    margin-left: -16px;
}


// @media (max-width: 896px) {
.overflowHidden {

    &>div,
    .wunschberechnung_container {
        overflow: hidden !important;
        // height: 100vh; // this causes jumps on iphone10
        // height: -webkit-fill-available;
    }

    @media (max-width: 896px) {
        .main {
            overflow: hidden !important; // causes jumps on iphone but without it, there is a scroll during animations appearing, needs more investigation
            // height: 100vh; // causing jumps on iphone
            // height: -webkit-fill-available;
        }

    }

}

// }

.wunschberechnung_container {
    display: flex;
    justify-content: space-between;
    // overflow: hidden;
    padding: 28px;
    // height: 100vh;
    height: 100%;
    height: -webkit-fill-available;
    min-height: 100vh;
    // this removes unnecessary scroll on iphones
    min-height: -webkit-fill-available;

    .main {
        visibility: hidden;
    }

    // overflow: auto;

    // @media (min-width: 2560px) {
    //   padding: 1.9444vw;
    // }
    @media (max-width: 896px) {
        flex-direction: column;
        justify-content: flex-start;
        padding: 0 16px 16px 16px;
        overflow: auto;
        // height: auto;
    }

    @media (max-width: 400px) {
        // padding: 0 14px 16px 14px;
    }


    // For a split second, when old page is animating out and before new is animating in, we have 2 children.
    &:nth-child(2) {
        position: absolute;
        top: 0;
        max-width: 100vw;
        max-height: 100vh;
    }
}

.menuTopElem {
    transition: all ease-in-out 0.5s;
    margin-top: 12px;
    // @media (min-width: 2560px) {
    //   margin-top: 1.1111vw;
    // }
}

.menuElem {
    transition: all ease-in-out 0.5s, color ease-in-out 0.3s;
    color: var(--grey);

    &.completed {
        cursor: pointer;
    }

    &.current {
        transition: all ease-in-out 0.5s;
        color: var(--blue-light) !important;
        font-weight: 500;

    }

    // &.completed ~ div {
    //     color: var(--main)
    // }
}

.completed {
    color: var(--main) !important;
}

.menuLastElem {
    transition: all ease-in-out 0.5s;
    // @media (min-width: 2560px) {
    //   margin-bottom: 1.1111vw;
    // }
}

.visualisationPage {
    .menuLastElem {
        // this causes a jump when opening dropdown on desktop, outside of chart page
        margin-bottom: 23px;
    }




    // &.ReactModal__Body--open {
    //     .ReactModalPortal {
    //         opacity: 1;
    //         transition: all ease-in-out 0.3s;
    //     }
    // }

    // .ReactModalPortal {
    //     opacity: 0;
    //     transition: all ease-in-out 0.3s;
    // }
}

.dots {
    @media (max-width: 896px) {
        margin-top: 7px;
        // align-self: center;
        height: 1px;
        width: 10vw;
        // width: 15vw;
        text-align: center;
        // margin-left: 7%;
        margin-left: calc(7vw - 13px);

        &:before {
            letter-spacing: 6.5vw;
            // letter-spacing: 4.5vw;
            font-weight: bold;
            color: var(--grey);
            content: "...";
        }
    }

    @media(max-width: 630px) {

        //margin-left: 1vw;
        &:before {
            letter-spacing: 6.7vw;
            // letter-spacing: 3.7vw;
            margin-left: 1vw;
        }
    }

    @media (max-width: 550px) {
        &:before {
            margin-left: 0.2vw;
        }
    }

    @media (max-width: 500px) {
        &:before {
            letter-spacing: 6.3vw;
            // letter-spacing: 3.3vw;
            margin-left: 0.2vw;
        }
    }

    @media (max-width: 450px) {
        &:before {
            margin-left: -5px;
        }
    }

    @media (max-width: 400px) {
        // margin-left: 3.0vw;
    }

    @media (max-width: 320px) {
        margin-left: 1px;
    }
}

.ich-mochte {
    text-align: center;
    margin-bottom: 29px;
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    max-width: 470px;

    // @media (min-width: 2560px) {
    //   margin-bottom: 2.7778vw;
    //   font-size: 1.5277vw;
    //   line-height: 1.5277vw;
    // }
    @media (max-width: 896px) {
        margin-bottom: 20px !important;
        line-height: 27px;

    }
}

.mb-40 {
    margin-bottom: 40px;

    @media (max-width: 896px) {
        margin-bottom: 21px;
    }
}

.ques {
    width: 24px;
    height: 24px;
    display: block;

    // @media (min-width: 2560px) {
    //   width: 1.6666vw;
    //   height: 1.6666vw;
    // }
    @media (max-width: 896px) {
        display: none;
    }
}

.ques_mobile {
    display: block;
    margin-right: auto;

    @media (min-width: 896px) {
        display: none;
    }
}

.fragenBeant {
    @media (max-width: 896px) {
        display: none;
    }
}

.done {
    height: 123px;
    cursor: pointer;
    margin-bottom: 40px;
    // @media (min-width: 2560px) {
    //   height: 8.5417vw;
    //   margin-bottom: 2.7778vw;
    // }
}

// .fragenBeant-done {
//     @media (max-width: 896px) {
//         margin-bottom: 80px;
//     }
// }
.fillToggle {
    transition: fill ease-in-out 0.3s;
}

.backButton {
    padding: 0;
    color: var(--blue);
    // margin-bottom: 8px;
    font-size: 12px;
    font-weight: bold;
    // line-height: 15px;
    text-align: right;
    border: none;
    background-color: initial;
    transition: all ease-in-out 0.2s;
    // line-height: 32px;
    line-height: 36px;

    .buttonText {
        display: inline-block;
        color: var(--blue);
        font-weight: inherit;
        padding: 0;

        // &:hover {
        //     transition: color ease-in-out 0.3s;
        //     color: var(--blue-light);

        // }
    }

    // @media (min-width: 2560px) {
    //   margin-bottom: 1.6667vw;
    //   font-size: 0.8333vw;
    //   line-height: 1.0417vw;
    // }
    @media (max-width: 896px) {
        height: 32px;
        // margin-top: 16px;
        // margin-left: 8px;
        margin-bottom: 16px;

        background-color: white;
        border-radius: 8px;

        .buttonText {
            // display: none !important;
        }
    }

    &:hover {
        .buttonText {
            color: var(--blue-light);
        }

        .fillToggle {
            fill: var(--main);
        }
    }
}

.text {
    font-size: 14px;
    letter-spacing: -0.17px;
    line-height: 20px;
    font-weight: 400;
    text-align: center;
    margin-top: -7px;

    // @media (min-width: 2560px) {
    //   font-size: 0.9722vw;
    //   letter-spacing: -0.0118vw;
    //   line-height: 1.3889vw;
    //   margin-top: -0.4861vw;
    // }
    @media (max-width: 896px) {
        text-align: left;
    }
}

#step4 {
    .labelContainer {
        cursor: default !important;

    }

    .toggleTrigger {
        pointer-events: none;
        cursor: default !important;

        div {
            // cursor: initial;
        }

        div:last-child {
            visibility: hidden;
        }
    }

    .menuElem {
        visibility: hidden;
    }

}

.circle {
    width: 24px;
    min-width: 24px;
    height: 24px;
    line-height: 20px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    background: #273338;
    margin: 6px 14px 0 14px;
    background: none;
    border: 2px solid var(--grey);
    color: var(--grey);
    transition: all ease-in-out 0.5s;

    &.inBackButton {
        border: none;
        // margin: auto;
        // margin-left: 0;
        // margin-right: 8px;

        svg {
            width: 100%;
            height: 100%;
            filter: none;
            line-height: 40px;
        }
    }

    svg {
        pointer-events: none;
        width: 10px;
        height: 10px;
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(67deg) brightness(200%) contrast(200%);
    }

    &.open {
        background-color: var(--blue-light);
        color: #FFF;
        border-color: var(--blue-light);

        &:not(.insideStep) {
            @media (max-width: 896px) {
                border-color: white;
                // background-color: var(--grey-light);
                background-color: rgba(162, 165, 166, 0.2);
                transform: scale(1.4);
            }
        }
    }

    &.insideStep {
        @media (max-width: 896px) {
            background-color: var(--main);
            color: #FFF;
            border-color: var(--main);

            &.open {
                @media (max-width: 896px) {
                    background-color: #fff;
                    border-color: #fff;
                    color: var(--main);
                    transform: scale(1.4);

                    .strokeWidthToggle {
                        stroke-width: 1px;
                    }
                }
            }
        }
    }

    &.finished {
        background-color: var(--main);
        color: white;
        border-color: var(--main);

        @media (max-width: 896px) {
            border-color: transparent;
        }

        &.open {
            @media (max-width: 896px) {
                background-color: #fff;
            }

            // @media (max-width: 896px) {
            //     box-shadow: 0 0 0 6px #fff;
            //     background-color: #fff;
            // }

            @media (max-width: 896px) {
                svg {
                    pointer-events: none;
                    width: 18px;
                    height: 16px;
                    filter: none;

                    .strokeToggle {
                        stroke: var(--blue-light) !important;
                    }

                    .strokeWidthToggle {
                        stroke-width: 1px;
                    }
                }
            }
        }
    }

    // @media (max-width: 896px) {
    // margin: 27px 0 0 0;
    // }
}