@import '../chartSettings.scss';

.blockPartialPension {
    // margin-top: 0 !important;
    // flex: 1 1 auto;

    #barPartialPensionSalaryWrapper {
        @extend .lightGreyGradient;
        //   background: linear-gradient(0deg, #E1E4E6 0%, #D2D4D6 100%);
    }

    #barPartialPension {
        @extend .blueGradient;
        // background-color: #c5d787;
        width: 100%;
        color: var(--main);
        transition: max-height 0.3s ease-in-out;
    }
}