@import '../Chart/chartSettings.scss';

body {
    .grid {
        opacity: 1;
        visibility: visible;
    }

    &:not(.dragStarted) {
        .gridYears {
            .hasHandle+.equals1stStep {

                .value,
                .tick {
                    opacity: 0;
                }
            }

            .gridYear {

                &.isNominal, &.isBirthday {
                    .dotWrapper {
                        width: 35px;
                        margin-right: 15px;
                        margin-left: auto;
                        // transform: scale(1.5);
                    }

                    .value,
                    .tick {
                        opacity: 0 !important;
                    }
                }

                &.nominalPrev {
                    .dotWrapper {
                        width: 35px;
                        margin-left: 6px;
                        margin-right: auto;
                        // transform: scale(1.5);
                    }
                }
            }
        }
    }

    // dragStarted class lasts longer than .react-draggable-transparent-selection
    &.dragStarted {
        .handleWrapper {
            transition: top ease-in-out 0.3s, opacity ease-in-out 0.3s;

            .remove {
                opacity: 0;
            }

            &:not(.current) {
                top: 22px;
                // opacity: 0.5 !important;
                //filter: brightness(2);

                svg {
                    transform: scale(.45);
                    opacity: .2;
                }

                // top: 25px;
                .monthLabel {
                    opacity: 0;
                    transform: translateX(-27%);
                }
            }


            @media (min-width: 897px) {
                // &:not(.react-draggable-dragged) {

                &:not(.current) {

                    svg,
                    div {
                        transition: top ease-in-out 0.3s, opacity ease-in-out 0.3s, transform ease-in-out 0.3s, filter ease-in-out 0.3s;
                        transform: scale(0.5);
                    }

                    &>div {
                        opacity: 0;
                    }

                }
            }

        }

        // }

        .dotWrapper {
            &.hideable {
                display: none;
            }
        }

        .gridYears {
            .gridYear {
                // flex-grow: 0;
                // flex-shrink: 0;
                flex: 1 0 calc(100% / 12);
                // width: calc(100% * 1 / 12);

                &.hasHandle {

                    .value,
                    .tick {
                        opacity: 1 !important;
                    }
                }

            }
        }

        // Prevent any clicks when BE call is in progress
        &:not(.react-draggable-transparent-selection) {
            pointer-events: none;

        }
    }

    // this class is removed just after handle release
    &.react-draggable-transparent-selection {

        .handleWrapper {
            &.react-draggable-dragging {
                visibility: visible !important;
                z-index: 11;

                .remove {
                    opacity: 0;
                }

                .yearInHandle {
                    opacity: 0;
                }
            }
        }

        .gridYear {
            &.equals1stStep {
                visibility: visible;
            }

            .valueDot {

                .value,
                .dotWrapper {
                    transition: all ease-in-out 0.3s;
                }

                .tick {
                    opacity: 1;
                }
            }

            &.current {
                visibility: visible;
            }
        }
    }
}

.grid {
    display: flex;
    flex: 0 1 auto;
    // height: 216px;
    height: 160px;
    position: relative;
    // padding-bottom: 40px;
    // z-index: 10;
    align-items: flex-start;
    // flex-direction: row-reverse;
    padding-left: $chartLeftPadding;
    padding-right: $chartRightPadding;
    overflow: hidden;
    transition: opacity ease-in-out 0.3s;

    @media (max-width: 896px) {
        height: auto;
        flex: 1 1 auto;
    }

    .gridYears {
        width: 100%;
        display: flex;
        visibility: hidden;

        .gridYear {
            margin-top: 14px;
            transition: all ease-in-out 0.3s;



            &.noShrink {
                // flex-shrink: 0 !important;
            }

            &[data-age="70"] {
                max-width: 0;

                .dotWrapper {
                    visibility: hidden;
                }
            }

            &.currentPrev3,
            &.currentNext3 {
                .tick {
                    opacity: 0.4;
                }

                .value {
                    opacity: 0.4;
                }
            }

            &.currentPrev2,
            &.currentNext2 {
                .tick {
                    opacity: 0.6;
                }

                .value {
                    opacity: 0.6;
                }
            }

            &.currentPrev,
            &.currentNext {
                .tick {
                    opacity: 0.8;
                }

                .value {
                    opacity: 0.8;
                }
            }

            &.current {
                .tick {
                    text-align: center;
                    opacity: 1;
                }

                .valueDot {
                    .dotWrapper {}

                    .value {
                        position: relative;
                        opacity: 1;
                    }
                }
            }

            .value {
                opacity: 0.2;
            }

            .valueDot {
                display: flex;
                flex-direction: row;
                align-items: center;
                flex: 1;
                // justify-content: space-around;
                justify-content: space-between;
                position: relative;
                margin-bottom: 8px;
                margin-left: -5px;
                color: var(--main);
                font-family: Inter;

                @media (max-width: 1083px) {
                    // justify-content: space-evenly;

                }

                @media (max-width: 896px) {
                    margin-left: -6px;
                }
            }

            .dotWrapper {
                width: 100%;
                display: flex;

                // margin-left: -6px;
                &:last-child {
                    // margin-right: 20px;
                    // margin-right: calc(26% - 3px);
                }
            }

            .dot {
                margin: auto;
                height: 2px;
                width: 2px;
                background-color: var(--grey);
                border-radius: 50%;

                @media (max-width: 896px) {
                    visibility: hidden;
                }

            }

            .tick {
                flex: 1 100%;
                text-align: center;
                display: flex;
                opacity: 0.2;
                transition: opacity ease-in-out 0.3s, transform ease-in-out 0.3s;
            }

        }
    }
}

body:not(.dragStarted) {
    .currentYear4 {
        .gridYear {
            flex-basis: calc(100% * 1 / 15);
            flex-shrink: 0;
            flex-grow: 0;

            &.at1 {
                flex-grow: 1;
                &~.current  {
                    opacity: 0;
                }
            }

            &.at2 {
                flex-grow: 0.5;
                // &~.current  {
                //     opacity: 0;
                // }
            }

            &:not(.noShrink) {}

            .dotWrapper {
                // visibility: hidden;
            }

            &.hasHandle {
                flex-grow: 2;

                &:not(.first) {

                    .value,
                    .tick {
                        opacity: 1;
                    }
                }
            }
        }

    }

    .currentYear2p5 {
        &.earlyPension {
            .gridYear {
                // this is necessary because pillar on the right from early pension is always 5 * yearWidth
                flex-grow: 0;
                flex-shrink: 1;

                &.noShrink {
                    flex-grow: 0;
                }

                &.hasHandle {}

                &.has2Handles {}
            }
        }

        .gridYear {
            flex-basis: calc(100% * 1 / 15);
            // flex-shrink: 1;
            // flex-grow: 0.6;

            &:not(.noShrink) {}

            &.equals1stStep {
                .tick {
                    opacity: 1;
                }

                &.has2Handles {

                    // flex-grow: 14;
                    .dotWrapper {
                        width: 35px;
                        margin-right: 15px;
                        // transform: scale(1.5);
                    }

                    .value,
                    .tick {
                        opacity: 0 !important;
                    }
                }
            }

            &.hasHandle {
                // flex-grow: 0.334;
                flex-grow: 1;

                .dotWrapper {
                    // visibility: hidden;
                }

                &:not(.first) {

                    .value,
                    .tick {
                        // opacity: 0;
                    }
                }
            }

            &.has2Handles {
                flex-grow: 1;

            }
        }

    }


    .currentYear2 {
        &.earlyPension {
            .gridYear {
                // this is necessary because pillar on the right from early pension is always 5 * yearWidth
                flex-grow: 0;
                flex-shrink: 1;

                &.noShrink {
                    flex-grow: 0;
                }

                &.has2Handles.equals1stStep {
                    .tick {
                        opacity: 1;
                    }

                    &+.gridYear {

                        .value,
                        .tick {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .gridYear {
            flex-basis: calc(100% * 1 / 20);
            flex-shrink: 1;
            flex-grow: 0.6;

            &:not(.noShrink) {}

            .dotWrapper {
                // visibility: hidden;
            }

            &.equals1stStep {
                flex-grow: 14;

                .value,
                .tick {
                    opacity: 0 !important;
                }
            }

            &.hasHandle {
                flex-grow: 2;

                &.equals1stStep {
                    flex-grow: 4;
                }

                &:not(.first) {

                    .value,
                    .tick {
                        opacity: 0;
                    }
                }
            }

            &.has2Handles {
                // changed from 10 to 4 for 60,60,65 case
                // changed from 4 to 5 for 62.62.66 case women
                flex-grow: 5;
                // commented out for 60,65,65
                // &+.gridYear {
                //     .value,
                //     .tick {
                //         opacity: 0;
                //     }
                // }
            }

        }

    }

    .currentYear1p5 {
        &.earlyPension {
            .gridYear {
                // this is necessary because pillar on the right from early pension is always 5 * yearWidth
                flex-grow: 0;
                flex-shrink: 1;

                // &.noShrink {
                //     flex-grow: 0;
                // }

                &.hasHandle {}

                &.has2Handles.equals1stStep {
                    .tick {
                        opacity: 1;
                    }

                    &+.gridYear {

                        .value,
                        .tick {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .gridYear {
            flex-basis: calc(100% * 1 / 20);
            flex-shrink: 1;
            flex-grow: 0.6;

            &:not(.noShrink) {}

            .dotWrapper {
                visibility: hidden;
            }

            &.equals1stStep {
                flex-grow: 14;

                .value,
                .tick {
                    opacity: 0;
                }
            }

            &.hasHandle {
                flex-grow: 2;

                &:not(.first) {

                    .value,
                    .tick {
                        opacity: 0;
                    }
                }
            }

            &.has2Handles {
                flex-grow: 6;

                &+.gridYear {

                    .value,
                    .tick {
                        opacity: 0;
                    }
                }
            }

            &.has3Handles {
                flex-grow: 15;

                &+.gridYear {

                    .value,
                    .tick {
                        opacity: 0;
                    }
                }
            }
        }

    }

    .currentYear1 {
        &.earlyPension {
            .gridYear {
                // this is necessary because pillar on the right from early pension is always 5 * yearWidth
                flex-grow: 0;
                flex-shrink: 1;

                &.noShrink {
                    flex-grow: 0;
                }

                &.hasHandle {
                    &+.gridYear {

                        .value,
                        .tick {
                            opacity: 0;
                        }
                    }
                }

                &.has2Handles {
                    &+.gridYear {

                        .value,
                        .tick {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .gridYear {
            // flex-basis: calc(100% * 1 / 22);
            flex-basis: calc(100% * 1 / 30);
            flex-shrink: 1;
            flex-grow: 0.6;

            &:not(.noShrink) {}

            .dotWrapper {
                visibility: hidden;
            }

            &.equals1stStep {
                flex-grow: 14;

                .value,
                .tick {
                    // opacity: 0;
                }
            }

            &.hasHandle {
                flex-grow: 2;

                &.equals1stStep {
                    flex-grow: 4;

                    // &~.gridYear {

                    //     .value,
                    //     .tick {
                    //         opacity: 0;
                    //     }
                    // }
                }

                &:not(.first) {

                    .value,
                    .tick {
                        opacity: 0;
                    }

                }

                &+.gridYear {

                    .value,
                    .tick {
                        opacity: 0;
                    }
                }
            }

            &.has2Handles {
                flex-grow: 10;

                &+.gridYear {

                    .value,
                    .tick {
                        opacity: 0;
                    }
                }
            }

            &.has3Handles {
                flex-grow: 15;

                // &+.gridYear {

                //     .value,
                //     .tick {
                //         opacity: 0;
                //     }
                // }
            }

            &.has4Handles {
                flex-grow: 15;

                &+.gridYear {

                    .value,
                    .tick {
                        opacity: 0;
                    }
                }
            }

            &.has4HandlesNext1,
            &.has4HandlesNext2,
            &.has4HandlesNext3 {

                .value,
                .tick {
                    opacity: 0;
                }
            }
        }
    }
}

.handleWrapper {
    position: absolute;
    z-index: 10;
    left: 0;
    top: 25px;
    visibility: hidden;
    height: 115px;
    transition: top ease-in-out 0.3s;
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    /* Likely future */
    @media (max-height: 340px) {
        top: 20px;
    }

    .monthLabel {
        transition: opacity ease-in-out 0.3s;
        opacity: 1;
    }

    .remove {
        top: 113px;
        position: absolute;
        left: 0;
        right: 0;
        cursor: pointer;
        .strokeToggle {
            // stroke: var(--blue);
        }

        &:hover {
            .strokeToggle {
                // stroke: var(--blue-light);
            }
        }
    }

    &>svg {
        cursor: pointer;
        transition: transform ease-in-out 0.3s;
        transform: scale(.85);

        text {
            pointer-events: none;
        }
    }

    @media (max-width: 896px) {

        // top: 15px;
        .remove {
            top: 92px;

            @media (orientation: landscape) {
                // visibility: hidden;
            }

        }

        svg {
            transform: scale(0.8);

        }
    }
}

.handle0 {
    .remove {
        display: none;
    }
}

.monthLabel {
    transform: translateX(-27%);
    position: absolute;
    left: 0;
    right: 0;
    top: 74px;
    margin: auto;
    width: 150px;
    // transition: all ease-in-out 0.3s;

    @media (max-width: 896px) and (orientation: landscape) {
        // visibility: hidden;
        // top: 75px;
    }
}

.zuruckSetzenButton {
    position: absolute;
    bottom: 24px;
    right: 42px;
}
